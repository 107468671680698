import React, { useEffect, useContext, useState } from 'react';
import PageBanner from './general/PageBanner';
import Pagination from './general/Pagination';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import Web3Context from '../store/web3-context';
import MarketplaceContext from '../store/marketplace-context';
import { formatPrice, configEtherScanUrl } from '../helpers/utils';
import FullScreenLoader from './general/FullScreenLoader';

function Blog({ sellers }) {
    const web3Ctx = useContext(Web3Context);
    const marketplaceCtx = useContext(MarketplaceContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(16);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentAuthors = sellers.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        document.title = 'How to NFT | NFT Marketplace';
    }, []);

    // Pagination
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
    }

    // Create top sellers template
    const renderSellers = currentAuthors
        .sort((a, b) => (a.value < b.value ? 1 : -1))
        .map((seller, index) => {
            return (
                <div className='col-xl-3 col-lg-4 col-md-6' key={index}>
                    <div className='card bd-3 card-hover-minimal position-relative'>
                        <div className='card-body'>
                            <a
                                className='d-flex align-items-center text-reset text-decoration-none stretched-link'
                                href={configEtherScanUrl(web3Ctx.networkId, seller.address)}
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                <p className='fw-bold text-primary mb-0'>1.</p>
                                <div className='position-relative'>
                                    <div className='ms-3' style={{ width: '50px', height: '50px' }}>
                                        <Jazzicon address={seller.address} />
                                    </div>
                                    <div className='author-img-badge bg-primary text-white'>
                                        <i className='las la-check-double la-xs'></i>
                                    </div>
                                </div>
                                <div className='ms-3'>
                                    <h3 className='h6 mb-1 text-capitalize'>
                                        {uniqueNamesGenerator({
                                            dictionaries: [starWars],
                                        }).replace('_', ' ')}
                                        {web3Ctx.account === seller.address ? (
                                            <span className='seller-badge ms-2'>You</span>
                                        ) : null}
                                    </h3>
                                    <p className='text-sm text-primary mb-0'>
                                        {formatPrice(seller.value).toFixed(2)} <span className='text-muted'>ETH</span>
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        });

    return (
        <>
            <PageBanner heading={'How to NFT'} />

            <section className='py-5'>
                <div className='container pt-5'>
                    <div className='row gy-4 mb-5 align-items-stretch'>{renderSellers}</div>
					<div className='col-lg-12 ms-auto d-none d-lg-block'>
                        <img className='img-fluid mx-auto' src='https://i.ibb.co/D7dVnhh/Screenshot-106.png' alt='..' />
                    </div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam consectetur nibh nec iaculis. Suspendisse ut ante posuere, pharetra purus id, varius nulla. Integer ante tortor, rutrum sed ipsum a, laoreet sodales est. Morbi vel consequat arcu. Phasellus malesuada orci massa, ac semper ex finibus sit amet. Morbi consequat mauris non enim suscipit condimentum. Praesent tristique consequat odio, et commodo nunc placerat quis. Nunc egestas rhoncus turpis, in hendrerit nulla sodales feugiat. Mauris nec dolor non ante bibendum viverra. Nam mollis ultrices auctor.</p>

					<p>Suspendisse ut mauris metus. Nunc consequat ligula vitae lorem sodales sagittis. Morbi sodales tellus augue, a cursus urna eleifend ut. Nullam erat urna, sodales vitae elit vel, interdum mattis sapien. Etiam gravida ante faucibus maximus varius. Aenean egestas lacinia semper. Vivamus pellentesque, nisl non suscipit lobortis, nunc neque ornare lorem, id commodo ex arcu sed justo.</p>

					<div className='col-lg-12 ms-auto d-none d-lg-block'>
                        <img className='img-fluid mx-auto' src='https://i.ibb.co/X2mxBC4/Screenshot-107.png' alt='..' />
                    </div>
					<p>Aliquam tincidunt faucibus dui id posuere. Nullam aliquam sem eget tortor vestibulum ultricies. Cras semper laoreet condimentum. Quisque finibus massa vel mauris ullamcorper, id eleifend nisl commodo. Aliquam fermentum odio a magna rutrum iaculis ut vitae orci. Vivamus vitae ipsum sit amet ligula gravida ultricies sit amet vel nisl. Nam dignissim orci at justo imperdiet porttitor. Aliquam pulvinar dui ut bibendum molestie. Duis id ante vel erat mattis euismod in eget tellus. Integer lacinia tortor quis rutrum tempor. Nunc et leo eget ligula auctor varius ut aliquam dui. Proin vulputate suscipit sapien, quis blandit nisl vehicula non.</p>

					<p>Ut id dapibus ligula. Suspendisse potenti. Aenean at orci in libero porttitor ornare at eget risus. Proin in suscipit metus. Sed sagittis justo nec tempus laoreet. Proin et lorem vitae ipsum volutpat blandit. Nam arcu dui, pretium a consectetur quis, gravida sit amet lorem. Donec volutpat interdum justo.</p>

					<div class="embed-responsive embed-responsive-16by9">
						<iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/TxcpUqP4mRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  					</div>
					<p>Suspendisse congue auctor dictum. Phasellus feugiat eget dui vel rhoncus. Suspendisse quis nibh neque. Proin laoreet ex nunc, sed pharetra lectus aliquam vel. Sed fringilla, magna a viverra bibendum, risus tellus lacinia erat, id efficitur tellus nulla a risus. Praesent dapibus porttitor lorem, id auctor massa commodo in. Ut iaculis nunc nec elit eleifend, non semper tellus semper. Proin in augue id dui tempus posuere. Nulla facilisi.</p>

                </div>
            </section>
        </>
    );
}

export default Blog;
